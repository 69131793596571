import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';

import { useRef } from 'react'
import { themeContext } from '../../Context';
import { useContext } from 'react';

const Contact = () => {
    const form = useRef();

    const [done, setDone] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_pev2vdc', 'template_0epz4fr', form.current, 'EENYRq2ghPW4jvMIo')
            .then((result) => {
                console.log(result.text);
                setDone(true)
            }, (error) => {
                console.log(error.text);
            });
    };

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;


    return (
        <div className="contactForm" id='Contact'>
            <div className="contactLeft">
                <div className="awesome">
                    <span style={{color: darkMode? 'white' : ''}}>Get in Touch </span>
                    <span>Contact me</span>
                </div>
            </div>

            <div className="contactRight">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='user_name' className='user' placeholder='Name' />
                    <input type="email" name='user_email' className='user' placeholder='Email' />
                    <textarea name="message" className='user' placeholder='Message' />
                    <input type="submit" value='Send' className='button' />
                    <span>{done && "Thanks for contacting me!"}</span>
                </form>
            </div>
        </div>
    )
}

export default Contact