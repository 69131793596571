import React from 'react'
import './Intro.css'
import Github from '../../img/github.png'
import Linkedin from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import boy from '../../img/boy.png'
import thumbup from '../../img/thumbup.png'
import glassesimoji from '../../img/glassesimoji.png'
import Crown from '../../img/crown.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import { useContext } from 'react'
import { themeContext } from '../../Context'
import { motion } from "framer-motion"

const Intro = () => {

    const transition = { duration: 2, type: 'spring' }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="intro">
            <div className="introLeft">
                <div className="introName">
                    <span style={{ color: darkMode ? 'white' : '' }}>Hey I Am</span>
                    <span>Praddyota Hayaran</span>
                    <span>Frontend Developer with high level
                        experience in web designing and
                        development, producing the Quality
                        Work</span>
                </div>

                <button className="button introButton">Hire me</button>
                <div className="introIcons">
                    <a href="https://github.com/praddyotahayaran" target={'blank'}><img src={Github} alt="" /></a>
                    <a href="https://www.linkedin.com/in/praddyota-hayaran-245543201/" target={'blank'}><img src={Linkedin} alt="" /></a>
                    <a href="https://www.instagram.com/praddyotahayaran/?next=%2F" target={'blank'}><img src={Instagram} alt="" /></a>
                </div>


            </div>
            <div className="introRight">
                <img src={Vector1} alt="" />
                <img src={Vector2} alt="" />
                <img src={boy} alt="" />
                {/* animation */}
                <motion.img
                    initial={{ left: "-36%" }}
                    whileInView={{ left: "-24%" }}
                    transition={transition}
                    src={glassesimoji}
                    alt=""
                />

                <motion.div
                    initial={{ top: '-4%', left: '74vh' }}
                    whileInView={{ left: '68%' }}
                    transition={transition}

                    style={{ top: '-4%', left: '68%' }}
                    className='floatingDiv'>
                    <FloatingDiv image={Crown} txt1='Web' txt2='Developer' />
                </motion.div>

                <motion.div
                    initial={{ left: '9rem', top: '20.5rem' }}
                    whileInView={{ left: '1rem' }}
                    transition={transition}

                    style={{ top: '20.5rem', left: '1rem' }}
                    className='floatingDiv'>
                    <FloatingDiv image={thumbup} txt1='Best Design' txt2='Award' />
                </motion.div>

                {/* Blur Div */}

                <div className="blur"
                    style={{ background: 'rgb(238, 210, 255' }}>
                </div>
                <div className="blur"
                    style={{
                        background: '#C1F5FF',
                        top: '17rem',
                        width: '21rem',
                        height: '11rem',
                        left: '-9rem'
                    }}
                ></div>
            </div>
        </div>
    )
}

export default Intro