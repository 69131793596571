import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import Resume from './Resume.pdf'
import { themeContext } from '../../Context'
import { useContext } from 'react'
import { motion } from 'framer-motion'

const Services = () => {
    const transition = { duration: 1, type: "spring" }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className='services' id='Services'>
            {/* Left Side */}
            <div className="awesome">
                <span style={{ color: darkMode ? 'white' : '' }}>My Awesome</span>
                <span>Services</span>
                <span>
                    Carbonmade from an organic home project into
                    a full-scale<br /> portfolio platform home to more than one million unique
                    <br /> portfolios, with several active and ongoing projects.
                </span>

                <a href={Resume} download target={'blank'}>
                    <button className="button serviceButton"> Download CV </button>
                </a>
                {/* <div className="blur servicesBlur" style={{ background: '#ABF1FF94' }}></div> */}
            </div>

            {/* Right Side */}
            <div className="cards">

                <motion.div
                    initial={{ left: '26rem' }}
                    whileInView={{ left: "27rem" }}
                    transition={transition}
                    style={{ left: '27rem' }}>
                    <Card
                        emoji={HeartEmoji}
                        heading={'Design'}
                        detail={'Figma, Sketch, Photoshop, Adobe'} />
                </motion.div>

                {/* Second Card */}

                <div style={{ top: '12rem', left: '11rem' }}>
                    <Card
                        emoji={Glasses}
                        heading={'Developer'}
                        detail={"HTML, CSS, JavaScript, React"}
                    />
                </div>

                {/* Third Card */}
                <motion.div
                    initial={{ left: '25rem' }}
                    whileInView={{ left: "27rem" }}
                    transition={transition}

                    style={{ top: '18rem', left: '28rem' }}>
                    <Card
                        emoji={Humble}
                        heading={'UI/UX'}
                        detail={'User Interface & User Experience'}
                    />
                </motion.div>

            </div>
        </div>
    )
}

export default Services