import React from 'react'
import './Work.css'
import Upwork from '../../img/Upwork.png'
import Fiverr from '../../img/fiverr.png'
import Amazon from '../../img/amazon.png'
import Shopify from '../../img/Shopify.png'
import Facebook from '../../img/Facebook.png'
import { themeContext } from '../../Context'
import { useContext } from 'react'
import { motion } from 'framer-motion'


const Works = () => {
    // const transition = { duration: 2, type: 'spring' }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="works" id='Work'>
            <div className="awesome">
                <span style={{ color: darkMode ? 'white' : '' }}>Works for All these</span>
                <span>Brands & Clients</span>
                <span>
                    Carbonmade from an organic home project into
                    a full-scale<br /> portfolio platform home to more
                    than one million unique <br /> portfolios, with several
                    active and ongoing projects.
                </span>

                <button className="button serviceButton"> Hire me</button>
            </div>

            {/* Right Side */}
            <div className="workRight">

                <motion.div
                    initial={{ rotate: 45 }}
                    whileInView={{ rotate: 0 }}
                    viewport={{ margin: '-40px' }}
                    transition={{ duration: 3.5, type: 'spring' }}
                    className="workMainCircle">
                    <div className="workSecCircle">
                        <img src={Upwork} alt="" />
                    </div>
                    <div className="workSecCircle">
                        <img src={Fiverr} alt="" />
                    </div>
                    <div className="workSecCircle">
                        <img src={Amazon} alt="" />
                    </div>
                    <div className="workSecCircle">
                        <img src={Shopify} alt="" />
                    </div>
                    <div className="workSecCircle">
                        <img src={Facebook} alt="" />
                    </div>
                </motion.div>

                {/* Background Circles */}
                <div className="workbackCircle blueCircle"></div>
                <div className="workbackCircle yellowCircle"></div>
            </div>
        </div>
    )
}

export default Works